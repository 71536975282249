
















































import ICrudClient from "@/lib/ICrudClient";
import DataProvider from "@/lib/DataProvider";
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import FormContainer from "../FormContainer.vue";
import Loader from "../Loader.vue";
import { ActionType } from "@/data/models/ActionTypes";
import SubArrayForm from "../SubArrayForm.vue";
import EntitySelect from "../EntitySelect.vue";
import { Guid } from "guid-typescript";
@Component({
  components: {
    FormContainer,
    Loader,
    SubArrayForm,
    EntitySelect
  }
})
export default class SessionRequestForm extends Vue {
  @Prop()
  public id!: string;

  public provider!: DataProvider<ActionType>;

  public crud!: ICrudClient<ActionType>;

  private item: any = null;

  created() {
    this.provider = this.$service.providers.sessionRequests;
    this.crud = this.$service.crud.sessionRequests;
  }
  async mounted() {
    if (this.id === "new") {
      this.item = {
        value: "",
        actionId: null,
        questionnaire: null,
        questionnaireId: null
      };
    }
  }
}
